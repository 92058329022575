import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';
import { APECodes } from '@src/utils/apecodes';

import { isAMail, isASiren, isASiret } from '@app/helpers/ToolsHelper';

import { User } from '@app/store/login/login.store';

import CustomInput from '../customForm/customInput';
import CustomSwitch from '../customForm/customSwitch';
import DealershipListing from '../DealershipListing';
import { CompanyInfo, GDPRField } from '../Helpers/CreateCustomer';

type ProfessionalFormProps = {
    handleToggleFormProModal: (isProValue: boolean) => void;
    isVisiblePhoneNumber: boolean;
    isPro: boolean;
    update: boolean;
    userData: User;
    gdpr_fields: GDPRField;
    values: CompanyInfo;
    getConsentName: (dealership: string, gdprField: string) => string;
    getDealershipConsent: (dealership: string, gdprField: string) => ['string', boolean];
    formStep: number;
    proFunction: string[];
    setSelectedOtherFunction: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOtherFunction: boolean;
    proRole: string[];
    setSelectedOtherRole: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOtherRole: boolean;
    setConsentChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

const otherField = 'Autre';

const ProfessionalForm = ({
    handleToggleFormProModal,
    isVisiblePhoneNumber,
    isPro,
    update,
    userData,
    gdpr_fields,
    values,
    getConsentName,
    getDealershipConsent,
    formStep,
    proFunction,
    setSelectedOtherFunction,
    selectedOtherFunction,
    proRole,
    setSelectedOtherRole,
    selectedOtherRole,
    setConsentChanged,
}: ProfessionalFormProps) => {
    const Intl = useIntl();
    const { register, getValues, setValue, control } = useFormContext();
    const UppercaseLocale = (Locale as string).toUpperCase() as CountryCode;

    const [selectedProFunction, setSelectedProFunction] = useState(null);
    const [selectedProRole, setSelectedProRole] = useState(null);

    useEffect(() => {
        if (values.function) {
            setSelectedProFunction(proFunction.includes(values.function) ? values.function : otherField);
        } else {
            setSelectedOtherFunction(false);
        }

        if (values.role) {
            setSelectedProRole(proRole.includes(values.role) ? values.role : otherField);
        } else {
            setSelectedOtherRole(false);
        }
    }, []);

    return (
        <>
            <CustomSwitch name="isPro" handleToggleFormProModal={handleToggleFormProModal} active={isPro} />
            <div className="modal-customize-nav">
                <div className="modal-company">
                    <i
                        className={`${
                            formStep === 0 ? 'las la-building modal--primary' : 'las la-building modal--success'
                        }`}
                    />
                    <span className={`${formStep === 0 ? 'text-center modal--primary' : 'text-center modal--success'}`}>
                        Entreprise
                    </span>
                </div>
                <hr
                    className={`${
                        formStep === 0
                            ? 'modal-bar modal-bar--color-bar-primary'
                            : 'modal-bar modal-bar--color-bar-success'
                    }`}
                />
                <div className="modal-pro">
                    <i className={`${formStep === 1 ? 'las la-user modal--primary' : 'las la-user'}`} />
                    <span className={`${formStep === 1 ? 'text-center modal--primary flex-nowrap' : 'text-center'}`}>
                        Contact pro
                    </span>
                </div>
            </div>
            {formStep >= 0 && (
                <section className={formStep === 0 ? 'd-block' : 'd-none'}>
                    <CustomInput
                        name="siret"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.SIRET' })}
                        validation={{
                            validate: (value: string) => {
                                if (value && !isASiret(value)) {
                                    return Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE.SIREN.OR.SIRET.FORMAT',
                                    });
                                }
                                return true;
                            },
                        }}
                    />
                    <CustomInput
                        name="siren"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.SIREN' })}
                        validation={{
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            validate: (value: string) => {
                                if (value && !isASiren(value)) {
                                    return Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE.SIREN.OR.SIRET.FORMAT',
                                    });
                                }
                                return true;
                            },
                        }}
                    />
                    {update && (
                        <Form.Group controlId="create_ape">
                            <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.APE' })}</Form.Label>
                            <Controller
                                control={control}
                                name="ape"
                                render={({ value, onChange }) => (
                                    <Dropdown
                                        value={typeof value === 'object' ? `${value?.code} ${value?.value}` : value}
                                        options={APECodes.map((ape) => ({
                                            value: `${ape.code} ${ape.value}`,
                                            label: `${ape.code} ${ape.value}`,
                                        }))}
                                        onChange={(e) => {
                                            onChange(e.value);
                                        }}
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE',
                                        })}
                                        emptyFilterMessage={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.APE_DROPDOWN.SEARCH.NO_RESULT',
                                        })}
                                        filter
                                        filterBy="label"
                                        showClear
                                    />
                                )}
                            />
                        </Form.Group>
                    )}
                    {update && (
                        <CustomInput
                            name="companyLegalStatus"
                            label={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.CREATE.COMPANY_LEGAL_STATUS',
                            })}
                            validation={{
                                required: update ? false : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                        />
                    )}

                    <CustomInput
                        name="companyName"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.COMPANY_NAME' })}
                        validation={{
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        }}
                    />

                    <CustomInput
                        name="companyPhone"
                        label={Intl.formatMessage({
                            id: `CUSTOMERS.MODAL.${update ? 'UPDATE' : 'CREATE'}.COMPANY_PHONE`,
                        })}
                        validation={{
                            validate: (value: string) => {
                                const phone = parsePhoneNumberFromString(value || '', UppercaseLocale);

                                if (update) {
                                    if (value && !phone?.isValid?.()) {
                                        return Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT',
                                        });
                                    }

                                    return true;
                                }

                                if (value && !phone?.isValid?.()) {
                                    return Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT',
                                    });
                                }

                                return true;
                            },
                        }}
                    />

                    <CustomInput
                        name="address"
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.INFO.ADDRESS' })}
                        validation={register}
                    />
                    <CustomInput
                        name="zipcode"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.UPDATE.ZIPCODE' })}
                        validation={register}
                    />

                    <CustomInput
                        name="city"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.UPDATE.CITY' })}
                        validation={register}
                    />

                    <span>{Intl.formatMessage({ id: 'TRANSLATOR.REQUIRED_FIELDS' })}</span>
                    <br />
                </section>
            )}
            {formStep === 1 && (
                <>
                    <CustomInput
                        name="lastname"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.PRO.LASTNAME' })}
                        validation={{
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        }}
                    />
                    <CustomInput
                        name="firstname"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.PRO.FIRSTNAME' })}
                        validation={{
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        }}
                    />
                    <CustomInput
                        classNameFormGroup={update && !isVisiblePhoneNumber ? 'd-none' : ''}
                        name="proPhone"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.PHONE_OF_CONTACT' })}
                        validation={{
                            validate: (value: string) => {
                                const atLeastOneOf = [getValues('email'), value].some((item) => !!item);
                                const phone = parsePhoneNumberFromString(value || '', UppercaseLocale);

                                if (update) {
                                    if (value && !phone?.isValid?.()) {
                                        return Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT',
                                        });
                                    }

                                    return true;
                                }

                                if (!atLeastOneOf) {
                                    return Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE.MOBILE_OR_PHONE_OR_EMAIL',
                                    });
                                }

                                if (value && !phone?.isValid?.()) {
                                    return Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT',
                                    });
                                }

                                return true;
                            },
                        }}
                    />
                    <CustomInput
                        name="email"
                        label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.EMAIL' })}
                        validation={{
                            validate: (value: string) => {
                                const atLeastOneOf = [getValues('proPhone'), value].some((item) => !!item);
                                if (!atLeastOneOf) {
                                    return Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE.MOBILE_OR_PHONE_OR_EMAIL',
                                    });
                                }

                                if (value && !isAMail(value)) {
                                    return Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT',
                                    });
                                }

                                return true;
                            },
                        }}
                    />
                    <Form.Group controlId="create_function">
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.FUNCTION' })}</Form.Label>
                        <Controller
                            control={control}
                            name="function"
                            render={({ onChange }) => (
                                <Dropdown
                                    value={selectedProFunction}
                                    options={proFunction}
                                    onChange={(e) => {
                                        setSelectedOtherFunction(e.value === otherField);
                                        setSelectedProFunction(e.value);
                                        onChange(e.value);
                                    }}
                                    placeholder={Intl.formatMessage({
                                        id: 'TRANSLATOR.SELECT',
                                    })}
                                />
                            )}
                        />
                    </Form.Group>
                    {selectedOtherFunction && (
                        <Form.Group>
                            <Form.Label>
                                {Intl.formatMessage({
                                    id: 'CUSTOMERS.MODAL.CREATE.FUNCTION_OTHER',
                                })}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="function_other"
                                ref={register()}
                                defaultValue={getValues().function}
                                onChange={(e) => {
                                    setValue('function', e.currentTarget.value);
                                }}
                            />
                        </Form.Group>
                    )}
                    <Form.Group controlId="create_role">
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.ROLE' })}</Form.Label>
                        <Controller
                            control={control}
                            name="role"
                            render={({ onChange }) => (
                                <Dropdown
                                    value={selectedProRole}
                                    options={proRole}
                                    onChange={(e) => {
                                        setSelectedOtherRole(e.value === otherField);
                                        setSelectedProRole(e.value);
                                        onChange(e.value);
                                    }}
                                    placeholder={Intl.formatMessage({
                                        id: 'TRANSLATOR.SELECT',
                                    })}
                                />
                            )}
                        />
                    </Form.Group>
                    {selectedOtherRole && (
                        <Form.Group>
                            <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.ROLE_OTHER' })}</Form.Label>
                            <Form.Control
                                type="text"
                                name="role_other"
                                ref={register()}
                                defaultValue={getValues().role}
                                onChange={(e) => {
                                    setValue('role', e.currentTarget.value);
                                }}
                            />
                        </Form.Group>
                    )}
                    <DealershipListing
                        userData={userData}
                        values={values}
                        getDealershipConsent={getDealershipConsent}
                        gdpr_fields={gdpr_fields}
                        getConsentName={getConsentName}
                        setConsentChanged={setConsentChanged}
                    />
                </>
            )}
        </>
    );
};

export default ProfessionalForm;
