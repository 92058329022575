import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { isContactNumberValid } from '@app/helpers/FormHelper';
import { isAMail } from '@app/helpers/ToolsHelper';

import { getCustomerAgeRange } from '@app/pages/customers/Helpers/Customer';
import { User } from '@app/store/login/login.store';

import CustomCheckbox from '../customForm/customCheckbox';
import CustomDatePicker from '../customForm/customDatePicker';
import CustomInput from '../customForm/customInput';
import CustomSwitch from '../customForm/customSwitch';
import DealershipListing from '../DealershipListing';
import { CompanyInfo, GDPRField } from '../Helpers/CreateCustomer';

type IndividualFormProps = {
    handleToggleFormProModal: (isProValue: boolean) => void;
    isVisiblePhoneNumber: boolean;
    isPro: boolean;
    update: boolean;
    userData: User;
    gdpr_fields: GDPRField;
    values: CompanyInfo;
    getConsentName: (dealership: string, gdprField: string) => string;
    getDealershipConsent: (dealership: string, gdprField: string) => ['string', boolean];
    context: string;
    setConsentChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

const IndividualForm = ({
    handleToggleFormProModal,
    isVisiblePhoneNumber,
    isPro,
    update,
    userData,
    gdpr_fields,
    values,
    getConsentName,
    getDealershipConsent,
    context,
    setConsentChanged,
}: IndividualFormProps) => {
    const Intl = useIntl();
    const { register, getValues } = useFormContext();
    const { startYear, endYear } = getCustomerAgeRange();

    const validateContactNumber = (value: string, isPhoneNumber = false) => {
        // If we are updating(all context FR, BE..), no need to validate if no value is provided
        if (update && !value) return true;

        const otherContactType = isPhoneNumber ? 'mobile' : 'phone';

        // Check if at least one contact method is provided
        const atLeastOneContactProvided = value || getValues(otherContactType) || getValues('email');
        if (!atLeastOneContactProvided) {
            return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.MOBILE_OR_PHONE_OR_EMAIL' });
        }

        if (!isContactNumberValid(value, isPhoneNumber, context)) {
            return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT' });
        }

        return true;
    };

    const civilityOptions = [
        { value: 'mme', labelId: 'TRANSLATOR.MRS' },
        { value: 'mr', labelId: 'TRANSLATOR.MR' },
    ];

    return (
        <>
            <Form.Group>
                <CustomSwitch name="isPro" handleToggleFormProModal={handleToggleFormProModal} active={isPro} />
            </Form.Group>
            <CustomCheckbox
                name="civility"
                label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.CIVILITY' })}
                options={civilityOptions}
                validation={{
                    required: update ? false : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                }}
            />
            <CustomInput
                name="lastname"
                label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.LASTNAME' })}
                validation={{
                    required: update ? false : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                }}
            />
            <CustomInput
                name="firstname"
                label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.FIRSTNAME' })}
                validation={{
                    required: update ? false : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    validate: (value: any, formValues: any) => {
                        const reg: RegExp = new RegExp(/ (ou|et|\/|-|\\|&|_|\||#) /, 'i');
                        if (values.firstname?.toLocaleLowerCase().match(reg)) {
                            return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.FIRSTNAME.ERROR.ALONE' });
                        }
                        return true;
                    },
                }}
            />
            <CustomDatePicker
                name="birthday"
                label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.BIRTHDAY' })}
                dateFormat="DD/MM/YYYY"
                startYear={startYear}
                endYear={endYear}
                locale={Locale}
            />
            <CustomInput
                classNameFormGroup={update && !isVisiblePhoneNumber ? 'd-none' : ''}
                name="mobile"
                label={Intl.formatMessage({
                    id: `CUSTOMERS.MODAL.${update ? 'UPDATE' : 'CREATE'}.MOBILE`,
                })}
                validation={{
                    validate: (value: string) => validateContactNumber(value, false),
                }}
            />
            <CustomInput
                classNameFormGroup={update && !isVisiblePhoneNumber ? 'd-none' : ''}
                name="phone"
                label={Intl.formatMessage({
                    id: `CUSTOMERS.MODAL.${update ? 'UPDATE' : 'CREATE'}.PHONE`,
                })}
                validation={{
                    validate: (value: string) => validateContactNumber(value, true),
                }}
            />
            <CustomInput
                name="email"
                label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.EMAIL' })}
                validation={{
                    validate: (value: string) => {
                        const atLeastOneOf = [getValues('phone'), getValues('mobile'), value].some((item) => !!item);
                        if (!atLeastOneOf) {
                            return Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.CREATE.MOBILE_OR_PHONE_OR_EMAIL',
                            });
                        }

                        if (value && !isAMail(value)) {
                            return Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT',
                            });
                        }

                        return true;
                    },
                }}
            />
            <CustomInput
                name="address"
                label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.UPDATE.ADDRESS' })}
                validation={register}
            />
            <CustomInput
                name="zipcode"
                label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.UPDATE.ZIPCODE' })}
                validation={register}
            />
            <CustomInput
                name="city"
                label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.UPDATE.CITY' })}
                validation={register}
            />
            <DealershipListing
                userData={userData}
                values={values}
                getDealershipConsent={getDealershipConsent}
                gdpr_fields={gdpr_fields}
                getConsentName={getConsentName}
                setConsentChanged={setConsentChanged}
            />
        </>
    );
};

export default IndividualForm;
