// library imports
import { useEffect } from 'react';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

// helpers imports
import { isContactNumberValid } from '../helpers/FormHelper';
import { isAMail } from '../helpers/ToolsHelper';

export const useCheckPhoneValidity = (values, setError, clearErrors, validate, context) => {
    const checkPhoneNumberValidity = (fieldName) => {
        const value = values[fieldName];

        const isValid = isContactNumberValid(value, fieldName === 'phone', context);

        if (value && !isValid) {
            setError(fieldName, {
                type: validate,
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT' }),
            });
        } else {
            clearErrors(fieldName);
        }
    };

    useEffect(() => {
        checkPhoneNumberValidity('phone');
    }, [values.phone, clearErrors, setError, validate, context]);

    useEffect(() => {
        checkPhoneNumberValidity('mobile');
    }, [values.phone, clearErrors, setError, validate, context]);

    return null;
};

export const useCheckEmailValidity = (email, setError, clearErrors, validate) => {
    // check validity for 'email' field
    useEffect(() => {
        if (email && !isAMail(email)) {
            setError('email', {
                type: validate,
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT' }),
            });
        } else {
            clearErrors('email');
        }
    }, [email, clearErrors, setError, validate]);

    return null;
};
